
import { defineComponent } from 'vue'
import { getDispatch, DispatchDto, dispatchDelete, dispatchArchive } from '@/api/dispatch'
import { anit } from '@/utils/util'
import alertBox from '@/components/alert-box.vue'

export default defineComponent({
    name: 'Dispatch',
    components: {
        'alert-box': alertBox
    },
    data () {
        return {
            isViewVideo: false,
            videoList: [],
            searchValue: '',
            searchText: '',
            pageNum: 1,
            pageSize: 10,
            total: 0,
            tableData: [] as DispatchDto[]
        }
    },
    created () {
        this.handleCurrentChange()
    },
    methods: {
        async handleCurrentChange () {
            const result = await getDispatch({
                page: this.pageNum,
                pageSize: this.pageSize,
                search: this.searchValue
            })
            this.tableData = result.data
            this.total = result.total
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.handleCurrentChange()
        },
        // 表格中根据状态筛选数据
        filterStatus (value: string) {
            // 使用anit防止多次触发
            if (anit()) {
                this.handleCurrentChange()
            }
            return true
        },
        async onDispatchDelete (id: number | string) {
            await this.$util.confirm('', '确定删除该被派遣单位？')
            await dispatchDelete({ companyId: id })
            await this.handleCurrentChange()
            this.$message.success('操作成功！')
        },
        async onDispatchArchive (id: number | string) {
            await this.$util.confirm('', '确定归档该被派遣单位？')
            await dispatchArchive({ companyId: id })
            await this.handleCurrentChange()
            this.$message.success('操作成功！')
        },
        preview (url: string) {
            window.open(url)
        },
        mutiPreview (files: []) {
            this.isViewVideo = true
            this.videoList = files
        },
        // 关闭预览视频
        onCancelViewVideo () {
            this.videoList = []
            this.isViewVideo = false
        }
    }
})
